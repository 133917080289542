<template>
  <!--
    The view for the ApplicationInfo-component
  -->
  <Portlet
    :title="$t('applicationInfoView.applicationInfo')"
    icon="cog"
    class="applicationInfo"
  >
    <ApplicationInfoDetail />
  </Portlet>
</template>

<script>
export default {
  name: "ApplicationInfo",
  components: {
    ApplicationInfoDetail: () => import('@/components/ApplicationInfoDetail.vue')
  },
  metaInfo () {
    return {
      title: this.$t('applicationInfoView.applicationInfo')
    }
  }
}
</script>
